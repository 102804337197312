.pageContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100vh;
}

.title {
   font-size: 10rem;
   margin-bottom: 1rem;
}

.logoutButton {
   font-family: 'Lato';
   padding: 0.5rem 1rem;
   background-color: #e91e63;
   color: white;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   font-size: 1.2rem;
}
